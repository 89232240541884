.custom-swiper {
  width: 100%;
  height: 100%;
}

.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev,
.custom-swiper .adview-gallery-arrow-button {
  width: 32px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(108, 143, 177, .5);
  border: none;
  flex-shrink: 0;
  padding: 0;
  z-index: 3;
}

.swiper-button-next {
  right: 0 !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.swiper-button-prev {
  left: 0 !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.swiper-button-next .bottom {
  bottom: 0 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.thumbnail-prev-button {
  height: 32px !important;
  width: 64px !important;
  top: 0 !important;
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.thumbnail-next-button {
  height: 32px !important;
  width: 64px !important;
  bottom: 0 !important;
  border-radius: 0 !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.thumbnail-prev-button:hover {
  color: white;
  background-color: rgba(108, 143, 177, 1) !important;
  opacity: 50% !important;
}

.thumbnail-next-button:hover {
  color: white;
  background-color: rgba(108, 143, 177, 1) !important;
}

.thumbnail-prev-button:disabled {
  visibility: hidden;
}

.thumbnail-next-button:disabled {
  visibility: hidden;
}

.thumbnail-prev-button:hover,
.thumbnail-next-button:hover,
.custom-swiper .swiper-button-next:hover,
.custom-swiper .swiper-button-prev:hover {
  background-color: rgba(108, 143, 177, 1) !important;
}

.custom-swiper .swiper-button-next:after,
.custom-swiper .swiper-button-prev:after {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-swiper .swiper-button-next.navigation-disabled,
.custom-swiper .swiper-button-prev.navigation-disabled {
  display: none !important;
  pointer-events: none;
}

.custom-swiper .swiper-button-next.swiper-button-lock,
.custom-swiper .swiper-button-prev.swiper-button-lock {
  display: none;
}

.custom-swiper .swiper-slide {
  box-sizing: border-box;
}

.custom-horizontal {
  display: inline;
  background-color: white !important;
  border-radius: 4px;
  right: 16px;
  bottom: 16px !important;
  left: auto !important;
  width: unset !important;
  padding-left: 8px;
  padding-right: 8px;
}

@media only screen and (max-width: 640px) {
  #tiles-swiper .swiper-button-next,
  #tiles-swiper .swiper-button-prev {
      /* 47px from top by deisgn plus half the height of the button*/
      top: calc(47px + 32px);
    }
  .custom-swiper .swiper-button-next,
  .custom-swiper .swiper-button-prev {
    top: 120px;
  }

  .custom-horizontal {
    bottom: unset !important;
    top: calc(240px - 36px) !important;
  }
}
