
.countdown-container {
    position: fixed;
    /* min-width: 331px;
    max-width: 361px; */
    text-align: center;
    font-size: 20px;
    right: 0;
    top: calc(50% - 175px);
    z-index: 1100;
    padding: 15px 0px 15px 10px;
    border-radius: 40px 0 0 40px;
    background-color: #f07330;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.countdown-hidden-button {
    background-color: #336699;
    border: none;
    color: #fff;
    position: absolute;
    top: -29px;
    right: 0px;
    padding: 5px 40px;
    font-size: 14px;
    line-height: 19px;
    border-radius: 20px 0 0 0;
}

.countdown-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.countdown-text-number {
    font-family: "Montserrat", "Quicksand", sans-serif;
    font-size: 100px;
    font-weight: 700;
    line-height: 116px;
    display: inline-block;
}

.countdown-text-unit {
    display: flex;
    align-items: center;
    font-size: 100px;
    font-family: "Montserrat", "Quicksand", sans-serif;
    font-weight: 700;
    line-height: 34px
}

.countdown-text-description {
    text-align: center;
    line-height: 22px;
    font-weight: 700;
    font-size: 17px;
    width: 300px;
    margin: 0 auto;
}

.countdown-subtext {
    text-align: center;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    width: 300px;
    margin: 0 auto;
    margin-bottom: 8px;
}

.countdown__wrapper {
    margin: 24px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10px;
}

.countdown__item {
    position: relative;
    display: flex;
}

.countdown__item-number {
    font-size: 17px;
    font-weight: 700;
    line-height: 51px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.countdown__item:not(:last-child):after {
    content: ":";
    position: relative;
    color: #fff;
    font-weight: bold;
    top: 11px;
    display: block;
    padding: 0 4px;
}

.countdown__item-number > span {
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    padding: 0 13px;
}

.countdown__item-label {
    font-size: 15px;
    font-weight: 700;
}

.countdown-box {
    margin-bottom: 13px;
    padding-right: 10px;
}

.countdown-btn {
    border-radius: 4px;
    border: 1px solid #fff;
    padding: 8px 12px;
    color: inherit;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    line-height: 17px;
    min-width: 240px;
}

.jfg-countdown-open {
    position: fixed;
    font-weight: 600;
    background-color: #f07330;
    height: 80px;
    color: #fff;
    right: 0;
    top: 50%;
    z-index: 100;
    text-align: center;
    cursor: pointer;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border: none;
}

.countdown-small-text {
    font-family: "Montserrat", "Quicksand", sans-serif;
    font-size:  30px;
    line-height: 80px;
    margin: 0 0 0 3px;
}

.countdown-small-text__unit {
    font-family: "Montserrat", "Quicksand", sans-serif;
    font-size: 18px;
}

.countdown-small-container > span {
    position: relative;
    top: calc(50% - 10px);
}

@media (max-width: 768px) {

    .countdown-container {
        width: auto;
        border-radius: 18px 0 0 18px;
        font-size: 12px;
    }

    .countdown__wrapper {
        margin: 16px auto;
        /* justify-content: space-around; */
    }

    .countdown-text-number {
        line-height: 51px;
        font-size: 44px;
    }

    .countdown-text-unit {
        line-height: 44px;
        font-size: 43px;
    }

    .countdown__item-number {
        line-height: 32px;
        font-size: 15px;
    }

    .countdown__item-number > span {
        padding: 0 6px;
    }

    .countdown__item:not(:nth-child(1)):after {
        content: "";
    }

    /* .countdown__item:nth-child(3) {
        display: none;
    } */

    .countdown-text-description {
        font-size: 14px;
        line-height: 20px;
        padding: 0;
        width:200px;
    }

    .countdown-subtext {
        font-size: 10px;
        line-height: 14px;
        padding: 0;
        width:200px;
    }


    .countdown-btn {
        min-width: 96px;
        height: auto;
        border-radius: 4px;
        border: solid 1px #fff;
        white-space: normal;
        font-size: 12px;
    }

    .countdown-hidden-button {
        padding: 5px 20px;
    }

    .countdown__item:not(:nth-child(3)):after {
      top: 5px;
      right: -7px;
    }
}
